import React from "react";
import { ThemeProvider as MuiThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from "@mui/material";

let muiTheme = createTheme({
  palette: {
    // mode: props.themeType,
    background: {
      default: "#F9FAFE",
    },

    primary: {
      light: "#DFE3EC",
      main: "#212A34",
      dark: "rgb(3, 3, 3)",
    },
    secondary: {
      light: "#353535",
      main: "#EFCA00",
      // dark: "#212A34",
    },

    accentTwo: { main: "rgb(225, 191, 165)" },
    accentThree: { main: "rgb(227, 216, 197)" },
  },
  paragraphLarge: {
    fontSize: 24,
  },
  components: {
    // MuiInput: {
    //   styleOverrides: {
    //   underline: {
    //     "&:after": {
    //       borderBottom: `1px solid ${colors.red[500]}`,
    //     },
    //     "&&&&:hover:before": {
    //       borderBottom: `2px solid ${colors.red[500]}`,
    //     },
    //   },
    //   },
    // },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: 2,
          paddingBottom: 2,
        },
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32,
        },
      }
    },
    // MuiToolbar: {
    //   styleOverrides: {
    //   regular: {
    //     minHeight: 47,
    //   },
    //   root: {
    //     minHeight: 47,
    //   },
    //   },
    // },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          position: "relative",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FFFFFF",
          },
          "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FFFFFF",
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
              borderColor: "#FFFFFF",
            },
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FFFFFF",
            borderWidth: 1,
          },
        },
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          // "&.Mui-focused": {
          color: "#FFFFFF",
          // }
        },
      }
    },

    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: "NeuzeitGro-Bol, sans-serif",
          fontSize: "4rem",
        },
        h2: {
          fontFamily: "NeuzeitGro-Bol, sans-serif",
          fontSize: "4rem",
          // [theme.breakpoints.up('md')]: {
          //   fontSize: '1.4rem',
          // },
        },
        h3: {
          fontFamily: "NeuzeitGro-Bol, sans-serif",
          fontSize: "2.75rem",
          fontWeight: 500,
        },
        h4: {
          fontFamily: "NeuzeitGro-Bol, sans-serif",
          fontSize: "1.5rem",
        },
        h5: {
          fontFamily: "NeuzeitGro-Bol, sans-serif",
          fontSize: "2rem",
          fontWeight: 400,
        },
        h6: {
          fontFamily: "NeuzeitGro-Bol, sans-serif",
          fontSize: 24,
          fontWeight: 500,
        },
        body1: {
          lineHeight: "26px",
          fontFamily: "NeuzeitGro-Reg, sans-serif",
          fontSize: "1.25rem",
        },
        body2: {
          fontFamily: "NeuzeitGro-Reg, sans-serif",
          fontSize: "1rem",
        },
        subtitle1: {
          fontFamily: "NeuzeitGro-Bol, sans-serif",
        },
        subtitle2: {
          fontFamily: "NeuzeitGro-Bol, sans-serif",
        },
        caption: {
          fontFamily: "NeuzeitGro-Bol, sans-serif",
          fontSize: "0.875rem",
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        // Name of the styleSheet
        root: {
          // Name of the rule
          // borderRadius: 50,
          // padding: "16px 32px",
          // height: 32,
          fontSize: "1rem",
          fontFamily: "NeuzeitGro-Bol, sans-serif",

          // fontWeight: 400,
        },
      }
    },
    MuiInput: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            opacity: 1,
          },
        },
      }
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },

  typography: {
    useNextVariants: true,
  },
});

// muiTheme = responsiveFontSizes(muiTheme);
export default ({ element }) => {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        {element}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};
